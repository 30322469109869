<div class='main-nav'>
  <div class='navbar navbar-inverse navbar-light navbar-expand-xl'>
    <div class='navbar-header'>
      <!-- <button type='button' class='navbar-toggle' data-toggle='collapse' data-target='.navbar-collapse' [attr.aria-expanded]='isExpanded' (click)='toggle()'>
        <span class='sr-only'>Toggle navigation</span>
        <span class='icon-bar'></span>
        <span class='icon-bar'></span>
        <span class='icon-bar'></span>
      </button> -->
      <a class='navbar-brand' [routerLink]='["/"]'>
        <span>
          <img src="/assets/images/evision5-white.svg" class="d-inline-block align-top">
        </span>
        <!--<span localize="navigation.evision5.msg"></span>-->
      </a>
    </div>
    <div class="clearfix"></div>
    <div class='navbar-collapse collapse navmenu' [ngClass]='{ "in": isExpanded }'>
      <ul class='nav navbar-nav'>
        <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
          <a [routerLink]='["/"]' (click)='collapse()'>
            <icon icon="Home"></icon> <span localize="navigation.home.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]'
            [routerLinkActiveOptions]='{ exact: false }'>
          <a [routerLink]='["/medialibrary"]' (click)='collapse()'>
            <icon icon="FolderOpen"></icon> <span localize="navigation.fileexplorer.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]'
            [routerLinkActiveOptions]='{ exact: false }'>
          <a [routerLink]='["/medialists"]' (click)='collapse()'>
            <icon icon="List"></icon> <span localize="navigation.medialists.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
          <a [routerLink]='["/mediajobs"]' (click)='collapse()'>
            <icon icon="PlayCircle"></icon> <span localize="navigation.mediajobs.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: false }'>
          <a [routerLink]='["/scenes"]' (click)='collapse()'>
            <icon icon="Image"></icon> <span localize="navigation.scenes.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: false }'>
          <a [routerLink]='["/medialayouts"]' (click)='collapse()'>
            <icon icon="Columns"></icon> <span localize="navigation.medialayouts.label"></span>
          </a>
        </li>
        <li *ngIf="application.hasRole('contentmanager')||application.isAdmin"
            [routerLinkActive]='["link-active"]'
            [routerLinkActiveOptions]='{ exact: false }'>
          <a [routerLink]='["/mediadevices"]' (click)='collapse()'>
            <icon icon="Desktop"></icon> <span localize="navigation.mediadevices.label"></span>
          </a>
        </li>
        <li class="navbar-content-start"></li>
        <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: false }' *ngIf="application.featureEnabled('events')">
          <a [routerLink]='["/pwp/eventitems"]' (click)='collapse()'>
            <icon icon="Calendar"></icon> <span localize="navigation.pwp.eventitems.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: false }'>
          <a [routerLink]='["/pwp/articles"]' (click)='collapse()'>
            <icon icon="File"></icon> <span localize="navigation.pwp.articles.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: false }'>
          <a [routerLink]='["/pwp/htmltexts"]' (click)='collapse()'>
            <icon icon="Code"></icon> <span localize="navigation.pwp.htmltexts.label"></span>
          </a>
        </li>
        <li class="navbar-content-end"></li>
        <li [routerLinkActive]='["link-active"]' *ngIf="application.isAdmin" appRoleFunction="users.summary">
          <a [routerLink]='["/pwp/users"]' (click)='collapse()'>
            <icon icon="Users"></icon> <span localize="navigation.users.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]' *ngIf="application.isAdmin">
          <a [routerLink]='["/pwp/roles"]' (click)='collapse()'>
            <icon icon="UserTag"></icon> <span localize="navigation.roles.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]' *ngIf="application.isAdmin" appRoleFunction="settings">
          <a [routerLink]='["/settings"]' (click)='collapse()'>
            <icon icon="Cog"></icon> <span localize="navigation.settings.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]'>
          <a [routerLink]='["/account"]' (click)='collapse()'>
            <icon icon="User"></icon> <span localize="navigation.account.label"></span>
          </a>
        </li>
        <li [routerLinkActive]='["link-active"]'>
          <a (click)='doLogout()'>
            <icon icon="SignOut"></icon> <span localize="navigation.logout.label"></span>
          </a>
        </li>
        <li class="navbar-content-end"></li>
      </ul>

      <div class="clearfix"></div>

      <div class="navbar-text">
        <span>User: </span>
        <span>{{user}}</span>
      </div>

      <div class="navbar-text">
        <span>Version: {{version}}</span>
        <!--br />  <span localize="navigation.prolan.msg"></span-->
      </div>
      <div *ngIf="isSessionLost"
           class="navbar-text navbar-warning"
           localize="navigation.sessionexpired.msg">
      </div>
    </div>
  </div>
</div>

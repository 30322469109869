<div class="component-container" fxLayout="column">

  <div class="component-content-container">

    <a class="topic" [routerLink]='["/mediadevices"]'>
      <img src="/assets/images/landing-page.devices.png">
      <span localize="$:landingpage.devices.msg,title:landingpage.devices.title"></span>
    </a>
    <a class="topic" [routerLink]='["/mediajobs"]'>
      <img src="/assets/images/landing-page.jobs.png">
      <span localize="$:landingpage.jobs.msg,title:landingpage.jobs.title"></span>
    </a>
    <a class="topic" [routerLink]='["/medialists"]'>
      <img src="/assets/images/landing-page.medialists.png">
      <span localize="$:landingpage.medialists.msg,title:landingpage.medialists.title"></span>
    </a>

  </div>

</div>

<div class='container-fluid' style="height:100%">
  <div class='row' style="height:100%" *ngIf="mode===2">
    <router-outlet></router-outlet>
  </div>
  <div class='row panel-loading' style="height:100%" *ngIf="mode===1">
    <img class="loading-ani" src="/assets/images/loading.svg" />
  </div>
  <div class='row' style="height:100%" *ngIf="mode===0">
    <h1>No access - login is missing!</h1>
  </div>
</div>

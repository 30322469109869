import { Component, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MediaActivitiesDurationComponent } from '../mediaactivities-duration/mediaactivities-duration.component';
import { MediaActivitySingleComponent } from '../mediaactivity-single/mediaactivity-single.component';
import { MediaListSetLinkedListsComponent } from '../medialist-setlinklists/medialist-setlinklists.component';
import { MediaListSingleComponent } from '../medialist-single/medialist-single.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { IMediaActivity } from '../core/interfaces/IMediaActivity';
import { IMediaListExtended } from '../core/interfaces/IMediaListExtended';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { IRank } from '../core/interfaces/IRank';
import { IMediaListLink } from '../core/interfaces/IMediaListLink';
import { SettingsService } from '../core/services/data/settings.service';
import { IMediaSettingsCollection } from '../core/interfaces/IMediaSettingsCollection';
import { UriBuilderService } from '../../../../app-pwp/src/app/core/services/common/uribuilder.service';

@Component({
  selector: 'app-mediaactivities-summary',
  templateUrl: './mediaactivities-summary.component.html',
  styleUrls: ['./mediaactivities-summary.component.css']
})

export class MediaActivitiesSummaryComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private dialogGeneric: MatDialogRef<DialogComponent>;
  private dialogDuration: MatDialogRef<MediaActivitiesDurationComponent>;
  private dialogEditActivity: MatDialogRef<MediaActivitySingleComponent>;
  private dialogLinkedList: MatDialogRef<MediaListSetLinkedListsComponent>;
  private dialogEditMediaList: MatDialogRef<MediaListSingleComponent>;

  public displayedColumns: string[] = [];
  private columnsMediaActivities: string[] = ['sorthandle', 'rank', 'preview', 'title', 'durationFormatted', 'down', 'up', 'edit'];
  private columnsLinkedList: string[] = ['sorthandle', 'rank', 'previewlist', 'title', 'durationLinkedListFormatted', 'down', 'up', 'edit'];

  private mediaActivityID: string = null;
  private mediaListID: string = null;
  private mediaActivities: IMediaActivity[] = null;
  public mediaList: IMediaListExtended = null;

  private timelineSteps: TimelineStep[] = [];
  private timelineActivity: TimelineStep[] = [];

  public data: object[] = null;

  public isTimeline: boolean = false;
  private timelineFactor: number = 10;

  private minRank: number = null;
  private maxRank: number = null;

  public textUnitSeconds: string = application.getRawText('mediaactivities.summary.toolbar.timeline.seconds.title');
  private settings: IMediaSettingsCollection;

  constructor(@Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private route: ActivatedRoute,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private settingsService: SettingsService,
    private uriBuilderService: UriBuilderService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.http);

    this.settingsService.GetSettings().subscribe((_settings: IMediaSettingsCollection) => {
      this.settings = _settings;
    });

    //this.security.checkForRolesByName('devices.summary');
    this.loadMediaActivities();
  };

  public ngOnInit() {
    this.mediaListID = this.route.snapshot.paramMap.get('id');
    this.loadMediaList();
  };

  public onTimelineViewChangeHandler() {
    if (this.isTimeline) {
      this.buildTimelinSteps();
    }
  };

  private buildTimelinSteps() {
    this.timelineSteps = [];
    this.timelineActivity = [];

    var sum = 0;
    for (var i = 0; i < this.mediaActivities.length; i++) {
      var tmp = new TimelineStep();
      tmp.value = this.mediaActivities[i].duration;
      tmp.total = sum;
      this.timelineActivity.push(tmp);

      sum += tmp.value;
    }

    let step = 60;
    let total = step;
    while ((total - step) < this.mediaList.durationSum) {
      var s = new TimelineStep();
      s.value = step;
      s.total = total - step;
      this.timelineSteps.push(s)

      total += step;
    }
  };

  private factorUp() {
    this.timelineFactor++;
  };

  private factorDown() {
    this.timelineFactor--;
  };

  public getPreviewLink(_mediaActivity: IMediaActivity) {
    let uriBuilder = this.uriBuilderService.Get();
    uriBuilder.Parse(this.settings.webpresenterMediaDirectoryHttpValue).AddSegment(_mediaActivity.mediaUrl);
    let uri = uriBuilder.Build();
    return uri;
  };

  public getMediaUrlPathFromActivity(_mediaActivity: IMediaActivity) {
    var retval = "";
    var mediaUrl = "";
    if (_mediaActivity.mediaUrl === null || _mediaActivity.mediaUrl == "") {
      mediaUrl = "layout";
    }
    else {
      mediaUrl = encodeURIComponent(_mediaActivity.mediaUrl);
    }
    retval = evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + mediaUrl);
    return retval;
  };

  public getMediaUrlPath(_mediaActivityUrl: string) {
    return evision5.buildApi(this.baseUrl, 'files/thumbnail?mediaurl=' + encodeURIComponent(_mediaActivityUrl));
  };

  private loadData() {
    if (this.mediaList.linkedList) {
      this.displayedColumns = this.columnsLinkedList;
      this.loadLinkedMediaLists();
    }
    else {
      this.displayedColumns = this.columnsMediaActivities;
      this.loadMediaActivities();
    }
  };

  private loadMediaList() {
    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID))
      .subscribe(result => {
        this.mediaList = result.data as IMediaListExtended
        this.loadData();
      }, error => console.error(error));
  };

  private loadLinkedMediaLists() {
    this.minRank = null;
    this.maxRank = null;

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID + '/linkedlists'))
      .subscribe(result => {
        var tmp = result.data as IRank[];
        this.data = tmp;

        for (var i = 0; i < tmp.length; i++) {
          if (this.minRank === null || this.minRank > tmp[i].rank) {
            this.minRank = tmp[i].rank;
          }

          if (this.maxRank === null || this.maxRank < tmp[i].rank) {
            this.maxRank = tmp[i].rank;
          }
        }

      }, error => console.error(error));
  };

  private loadMediaActivities() {
    if (this.mediaListID === null) {
      return;
    }

    this.minRank = null;
    this.maxRank = null;

    this.http.get<IReturnState>(evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID + '/mediaactivities'))
      .subscribe(result => {
        var tmp = result.data as IMediaActivity[];
        var tmp2: IMediaActivity[] = [];

        for (var i = 0; i < tmp.length; i++) {
          //if (tmp[i].title.indexOf('3190') < 0)
          //  continue;

          tmp2.push(tmp[i]);

          if (this.minRank === null || this.minRank > tmp[i].rank) {
            this.minRank = tmp[i].rank;
          }

          if (this.maxRank === null || this.maxRank < tmp[i].rank) {
            this.maxRank = tmp[i].rank;
          }
        }

        this.mediaActivities = this.data = tmp2;
      }, error => console.error(error));
  };

  public rowDroppedHandler(_event: CdkDragDrop<string[]>) {
    // Swap the elements around
    moveItemInArray(this.data, _event.previousIndex, _event.currentIndex);

    var reordered = [];
    for (var i = 0; i < this.data.length; i++) {
      if (this.mediaList.linkedList) {
        var ml = this.data[i] as IMediaListLink;
        reordered.push({
          rank: i + 1,
          id: ml.mediaListID
        });
      }
      else {
        var ma = this.data[i] as IMediaActivity;
        reordered.push({
          rank: i + 1,
          id: ma.mediaActivityID
        });
      }
    }

    var url = this.mediaList.linkedList
      ? evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID + '/linkedlists/reorder')
      : evision5.buildApi(this.baseUrl, 'medialist/' + this.mediaListID + '/mediaactivities/reorder');

    this.http
      .post<IReturnState>(url, reordered)
      .subscribe(
        result => {
          this.loadData();
        },
        error => console.error(error));
  };

  public moveDown(_element: object) {
    var url = this.mediaList.linkedList
      ? evision5.buildApi(this.baseUrl, 'medialist/' + (_element as IMediaListLink).id + '/movedown')
      : evision5.buildApi(this.baseUrl, 'mediaactivity/' + (_element as IMediaActivity).mediaActivityID + '/movedown');

    this.http
      .post<IReturnState>(url, {})
      .subscribe(
        result => {
          if (result.success) {
            this.loadData();
          }
        },
        error => console.error(error));
  };

  public moveUp(_element: object) {
    var url = this.mediaList.linkedList
      ? evision5.buildApi(this.baseUrl, 'medialist/' + (_element as IMediaListLink).id + '/moveup')
      : evision5.buildApi(this.baseUrl, 'mediaactivity/' + (_element as IMediaActivity).mediaActivityID + '/moveup');

    this.http
      .post<IReturnState>(url, {})
      .subscribe(
        result => {
          if (result.success) {
            this.loadData();
          }
        },
        error => console.error(error));
  };

  public deleteItem(_element: object) {
    var msg = this.mediaList.linkedList
      ? 'mediaactivities.summary.linkedlist.delete.msg'
      : 'mediaactivities.summary.delete.msg';

    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText(msg);
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      var url = this.mediaList.linkedList
        ? evision5.buildApi(this.baseUrl, 'medialistlink/' + (_element as IMediaListLink).id)
        : evision5.buildApi(this.baseUrl, 'mediaactivity/' + (_element as IMediaActivity).mediaActivityID);

      this.http
        .delete<IReturnState>(url, {})
        .subscribe(
          _result => {
            var result = _result as IReturnState;
            if (result.success) {
              this.loadData();

              var msgDeleted = this.mediaList.linkedList
                ? 'mediaactivities.summary.linkedlist.deleted.msg'
                : 'mediaactivities.summary.deleted.msg';
              this.snackBarRef = this.snackBar.open(application.getRawText(msgDeleted), application.getRawText('common.close.msg'));
            }
            else {
              this.snackBarRef = this.snackBar.open(application.getRawText('common.error.retry.msg'), application.getRawText('common.close.msg'));
            }
          },
          error => console.error(error));
    });
  };

  public setDuration() {
    this.dialogDuration = this.dialog.open(MediaActivitiesDurationComponent, {
      autoFocus: true,
      height: '450px',
      width: '650px'
    });
    this.dialogDuration.componentInstance.item.mediaListID = this.mediaListID;
    this.dialogDuration.componentInstance.item.mediaListName = this.mediaList.listName;
    this.dialogDuration.componentInstance.onSaved.subscribe(() => {
      this.dialogDuration.close();
      this.loadMediaActivities();
    });
  };

  public editMediaActivity(_mediaActivityID: string) {
    if (this.mediaList.linkedList) {
      return;
    }

    this.mediaActivityID = _mediaActivityID;
    this.dialogEditActivity = this.dialog.open(MediaActivitySingleComponent, {
      autoFocus: true,
      height: '550px',
      width: '550px'
    });
    this.dialogEditActivity.componentInstance.mediaListID = this.mediaListID;
    this.dialogEditActivity.componentInstance.mediaActivityID = _mediaActivityID;
    this.dialogEditActivity.componentInstance.initMediaActivity();
    this.dialogEditActivity.componentInstance.onClose.subscribe(() => {
      this.dialogEditActivity.close();
      this.loadMediaActivities();
    });
  };

  public editMediaList() {
    this.dialogEditMediaList = this.dialog.open(MediaListSingleComponent, {
      autoFocus: true,
      height: '450px',
      width: '800px'
    });
    this.dialogEditMediaList.componentInstance.mediaListID = this.mediaListID;
    this.dialogEditMediaList.componentInstance.initMediaList();
    this.dialogEditMediaList.componentInstance.onClose.subscribe(() => {
      this.dialogEditMediaList.close();
      this.loadMediaList();
    });
  };

  public setLinkedLists() {
    this.dialogLinkedList = this.dialog.open(MediaListSetLinkedListsComponent, {
      autoFocus: true,
      height: '450px',
      width: '800px'
    });
    this.dialogLinkedList.componentInstance.init(this.mediaListID);
    this.dialogLinkedList.componentInstance.onClose.subscribe(() => {
      this.dialogLinkedList.close();
      this.loadMediaList();
      this.loadData();
    });
  };
}

class TimelineStep {
  value: number;
  total: number;
}

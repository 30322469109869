import { Component, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { EditDirectoryComponent } from './editdirectory/editdirectory.component';
import { PreviewFileComponent } from './previewfile/previewfile.component';
import { HttpWorker, HttpWorkerFactoryService } from '../../../../app-pwp/src/app/core/services/http/httpworkerfactory.service';
import { BusyIndicatorWorker, BusyIndicatorFactoryService } from '../../../../app-pwp/src/app/core/services/busyindicator/busyindicatorfactory.service';
import Directory from '../core/models/Directory';
import File from '../core/models/File';
import { DialogComponent } from '../../../../app-pwp/src/app/dialog/dialog.component';
import { application } from '../../../../app-pwp/src/app/core/globals';
import { IMediaSettingsCollection } from '../core/interfaces/IMediaSettingsCollection';
import { SecurityService } from '../../../../app-pwp/src/app/core/services/common/security.service';
import { ChunkedUploadService } from '../../../../app-pwp/src/app/core/services/data/chunkedupload.service';
import { SettingsService } from '../core/services/data/settings.service';
import { FileExplorerService } from '../core/services/data/fileexplorer.service';
import { evision5 } from '../core/globals';
import { IReturnState } from '../../../../app-pwp/src/app/core/interfaces/IReturnState';
import { UriBuilderService } from '../../../../app-pwp/src/app/core/services/common/uribuilder.service';
import { IWidgetDefinition } from '../core/interfaces/IWidgetDefinition';

/**
 *  This component provides a file explorer for different situations:
 *  - as kind of "windows explorer" and with ability to upload files.
 *  - as file selection for selection of one or more files for mediaactivities.
 *  - as copy dialog to select target to copy a file to.
 * */
@Component({
  selector: 'app-fileexplorer',
  templateUrl: './fileexplorer.component.html',
  styleUrls: ['./fileexplorer.component.css']
})
export class FileExplorerComponent {
  private http: HttpWorker;
  public busyIndicator: BusyIndicatorWorker;

  public onClose = new EventEmitter();
  public onSelected = new EventEmitter();
  public onAddAllFiles = new EventEmitter();
  public onAddSelectedFiles = new EventEmitter();

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  private initiated: boolean = false;
  public showMediaActions: boolean = false;
  public showDirectorySelection: boolean = false;
  public showNewFileName: boolean = false;
  public showUpload: boolean = true;
  public hideFilesList: boolean = false;

  private newFileName: string = "";
  private fileToCopy: File = null;

  public files: File[] = [];
  public directories: Directory[] = [];

  public selectedFiles: File[] = null;
  public selectedFile: File = null;

  private dialogCopyFileExplorer: MatDialogRef<FileExplorerComponent> = null;
  private dialogEditDir: MatDialogRef<EditDirectoryComponent> = null;
  private dialogGeneric: MatDialogRef<DialogComponent> = null;
  private dialogPreviewFile: MatDialogRef<PreviewFileComponent> = null;

  public path: string = '';
  public directoryStack: Directory[] = [];

  public textDragnDrop: string = application.getRawText('mediaactivity.single.toolbar.dropfiles.lbl');

  private displayedFilesColumnsMediaActions = ['fileicon', 'selection', 'preview', 'name', 'filesize', 'date', 'edit'];
  private displayedFilesColumnsDefault = ['fileicon', 'preview', 'name', 'filesize', 'date', 'edit'];
  private displayedFilesColumns = this.displayedFilesColumnsDefault;

  private displayedDirColumnsDefault: string[] = ['icon', 'name', 'date', 'edit'];
  private displayedDirColumns = this.displayedDirColumnsDefault;

  private statusDragnDrop: string = this.textDragnDrop;
  private uploadRunning: boolean = false;

  private settings: IMediaSettingsCollection = null;

  public textNewFileName: string = application.getRawText('filexplorer.form.copy.newfilename.title');
  public textNewDirectory: string = application.getRawText('filexplorer.form.copy.newdirectory.title');

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private security: SecurityService,
    private route: ActivatedRoute,
    private uploadService: ChunkedUploadService,
    private settingsService: SettingsService,
    private fileExplorerService: FileExplorerService,
    private httpWorkerFactory: HttpWorkerFactoryService,
    private busyIndicatorWorkerFactory: BusyIndicatorFactoryService,
    private uriBuilderService: UriBuilderService) {
    this.http = this.httpWorkerFactory.GetWorker();
    this.busyIndicator = this.busyIndicatorWorkerFactory.GetWorker();
    this.busyIndicator.Register(this.fileExplorerService);
    this.busyIndicator.Register(this.http);
    this.busyIndicator.Register(this.settingsService);
    this.busyIndicator.Register(this.uploadService);

    //this.security.checkForRolesByName('devices.summary');
    this.settingsService.GetSettings().subscribe((_settings: IMediaSettingsCollection) => {
      this.settings = _settings;
    });
  };

  public ngOnInit() {
    if (this.initiated) {
      return;
    }

    //this.mediaListID = this.route.snapshot.paramMap.get('id');
    this.initExplorer();
  };

  private getMediaUrlPath(_file: File) {
    let baseUrl = evision5.buildApi(this.baseUrl, '/');

    var uri = this.uriBuilderService.Get();
    uri.Parse(baseUrl)
      .AddSegment('files')
      .AddSegment('thumbnail')
      .AddQueryString('mediaurl', _file.url);

    var url = uri.Build();
    return url;
  };

  private setPathByDirectoryStack() {
    if (this.directoryStack.length > 0) {
      this.path = this.directoryStack[this.directoryStack.length - 1].path;
    }
    else {
      this.path = '';
    }
  };

  public enableDirectorySelection(_showDirectorySelection: boolean = true) {
    this.showDirectorySelection = _showDirectorySelection;
  };

  public hideFiles(_hideFilesList: boolean = true) {
    this.hideFilesList = _hideFilesList;
  };

  public showNewFileNameInput(_showNewFileName: boolean = true) {
    this.showNewFileName = _showNewFileName;
  };

  public enableMediaActions() {
    this.displayedFilesColumns = this.displayedFilesColumnsMediaActions;
    this.showMediaActions = true;
  }

  public initExplorer() {
    if (this.initiated) {
      return;
    }

    this.setPathByDirectoryStack();

    this.loadData();

    this.initiated = true;
  };

  public loadData() {
    this.loadFiles();
    this.loadDirectories();
  };

  public loadFiles() {
    this.files = [];

    var query = this.path != null && this.path.length > 0
      ? '?path=' + encodeURIComponent(this.path)
      : '';
    var url = evision5.buildApi(this.baseUrl, 'files/ofdirectory' + query);

    this.http.get<IReturnState>(url)
      .subscribe(result => {
        this.files = result.data as File[];
      }, error => console.error(error));
  };

  private loadDirectories() {
    var query = this.path != null && this.path.length > 0
      ? '?path=' + encodeURIComponent(this.path)
      : '';
    var url = evision5.buildApi(this.baseUrl, 'directories' + query);

    this.http.get<IReturnState>(url)
      .subscribe(result => {
        this.directories = result.data as Directory[];
      }, error => console.error(error));
  };

  private humanFileSize(size: number) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(1) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  };

  public close() {
    this.onClose.emit();
  };

  public selectFile(_element: File) {
    this.selectedFile = _element;
    this.onSelected.emit();
  };

  public selectDirectory(_element: Directory) {
    this.directoryStack.push(_element);
    this.path = _element.path;
    this.setPathByDirectoryStack();
    this.loadDirectories();
    this.loadFiles();
  };

  public directoryUp() {
    var tmp = [];
    for (var i = 0; i < this.directoryStack.length; i++) {
      if (i >= this.directoryStack.length - 1) {
        break;
      }

      tmp.push(this.directoryStack[i]);
    }
    this.directoryStack = tmp;

    this.setPathByDirectoryStack();
    this.loadData();
  };

  public applyAll() {
    this.selectedFiles = this.files;
    this.onAddAllFiles.emit();
  };

  private applySelection() {
    var tmp = [];
    for (var i = 0; i < this.files.length; i++) {
      if (this.files[i].selected) {
        tmp.push(this.files[i]);
      }
    }
    this.selectedFiles = tmp;

    this.onAddSelectedFiles.emit();
  };

  public selectDirectoryByBreadCrumbs(_element: Directory) {
    var tmp = [];
    for (var f = 0; f < this.directoryStack.length; f++) {
      tmp.push(this.directoryStack[f]);
      if (this.directoryStack[f] === _element) {
        break;
      }
    }
    this.directoryStack = tmp;

    this.setPathByDirectoryStack();
    this.loadData();
  };

  private dropped(_event: NgxFileDropEntry[]) {
    if (this.uploadRunning === true) {
      return;
    }

    // Calls event and passes TRUE if upload is allowed.
    this.fileExists(_event)
      .subscribe((_value) => {
        if (_value === true) {
          this.doUpload(_event);
        }
      });
  };

  private doUpload(_files: NgxFileDropEntry[]) {
    for (const droppedFile of _files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        // Check if the extension is allowed.
        var fileFormatIsValid = this.uploadService.HasValidExtension(droppedFile.fileEntry.name, this.settings.uploadFormatsValues);
        if (fileFormatIsValid === false) {
          this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.wrongext.msg'), application.getRawText('common.close.msg'));
          return;
        }

        // Upload file
        this.uploadRunning = true;
        var file = this.uploadService.UploadFileToFiles(droppedFile.fileEntry as FileSystemFileEntry, this.path, evision5);
        //var file = this.uploadService.UploadFileToFiles(droppedFile.fileEntry as FileSystemFileEntry, this.path);
        file.OnStatus.subscribe((_result: number) => {
          this.statusDragnDrop = (Math.round(_result)).toString() + ' %';
        });
        file.OnDone.subscribe((_result: IReturnState) => {
          this.uploadRunning = false;
          this.statusDragnDrop = this.textDragnDrop;

          if (_result.success) {
            this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.success.msg'), application.getRawText('common.close.msg'));
            this.loadFiles();
          }
          else if (_result.stateID == 96) {
            this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.wrongext.msg'), application.getRawText('common.close.msg'));
          }
          else if (_result.stateID == 97) {
            this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.fileexists.msg'), application.getRawText('common.close.msg'));
          }
          else if (_result.stateID == 98) {
            this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.error.msg'), application.getRawText('common.close.msg'));
          }
          else if (_result.stateID == 99) {
            this.snackBarRef = this.snackBar.open(application.getRawText('mediaactivity.single.upload.nowriteaccess.msg'), application.getRawText('common.close.msg'));
          }
        });
      }
      else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }

  };

  private fileExists(_files: NgxFileDropEntry[]): EventEmitter<boolean> {
    var onContinue = new EventEmitter<boolean>();

    var fileExists = [];
    var dic = this.getFilesAsDic();
    for (var i = 0; i < _files.length; i++) {
      var name = _files[i].relativePath,
        nameForDic = name.toLowerCase();
      if (dic[nameForDic] !== null && dic[nameForDic] !== undefined) {
        fileExists.push(name);
      }
    }

    if (fileExists.length > 0) {
      var fileNames = '';
      for (var f = 0; f < fileExists.length; f++) {
        fileNames = fileNames + fileExists[f];
        if (f < fileExists.length - 1) {
          fileNames = fileNames + ', ';
        }
      }

      var msg = fileExists.length === 1
        ? application.getRawText('mediaactivity.single.upload.fileexists.overwrite.msg').replace('{0}', fileNames)
        : application.getRawText('mediaactivity.single.upload.fileexists.overwrite.multiple.msg').replace('{0}', fileNames);

      this.dialogGeneric = this.dialog.open(DialogComponent, {
        autoFocus: true,
        height: '250px',
        width: '550px'
      });
      this.dialogGeneric.componentInstance.options.actionYes = true;
      this.dialogGeneric.componentInstance.options.actionNo = true;
      this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
      this.dialogGeneric.componentInstance.options.message = msg;
      this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
        onContinue.emit(_action.action === 'yes');
      });
    }
    else {
      setTimeout(() => {
        onContinue.emit(true);
      });
    }

    return onContinue;
  };

  private getFilesAsDic(): any {
    var dic = {};

    for (var f = 0; f < this.files.length; f++) {
      dic[this.files[f].name.toLowerCase()] = this.files[f];
    }

    return dic;
  };

  public newDirectory() {
    this.dialogEditDir = this.dialog.open(EditDirectoryComponent, {
      autoFocus: true,
      height: '400px',
      width: '600px'
    });
    this.dialogEditDir.componentInstance.init(this.path);
    this.dialogEditDir.componentInstance.onClose.subscribe(() => {
      this.loadData();

      this.dialogEditDir.close();
      this.dialogEditDir = null;
    });
  };

  public previewfile(_element: File) {
    this.dialogPreviewFile = this.dialog.open(PreviewFileComponent, {
      autoFocus: true,
      height: '80%',
      width: '80%'
    });

    this.dialogPreviewFile.componentInstance.init(this.path, _element, this.files);
    this.dialogPreviewFile.componentInstance.onClose.subscribe(() => {
      if (this.dialogPreviewFile.componentInstance.HasFileBeenEdited()) {
        this.loadFiles();
      }

      this.dialogPreviewFile.close();
      this.dialogPreviewFile = null;
    });

  }

  private deleteDirectory(_element: Directory) {
    this.dialogGeneric = this.dialog.open(DialogComponent, {
      autoFocus: true,
      height: '250px',
      width: '550px'
    });
    this.dialogGeneric.componentInstance.options.actionYes = true;
    this.dialogGeneric.componentInstance.options.actionNo = true;
    this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
    this.dialogGeneric.componentInstance.options.message = application.getRawText('filexplorer.delete.dir.msg');
    this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
      if (_action.action !== 'yes') {
        return;
      }

      this.fileExplorerService.DeleteDirectory(_element.path)
        .subscribe((_result: IReturnState) => {
          if (_result.success) {
            this.loadData();
            this.snackBarRef = this.snackBar.open(application.getRawText('common.deleted.msg'), application.getRawText('common.close.msg'));
          }
          else if (_result.stateID === 98) {
            this.snackBarRef = this.snackBar.open(application.getRawText('filexplorer.delete.dir.error.notexist.msg'), application.getRawText('common.close.msg'));
          }
          else // 99
          {
            this.snackBarRef = this.snackBar.open(application.getRawText('filexplorer.delete.dir.error.msg'), application.getRawText('common.close.msg'));
          }
        });
    });
  };

  private deleteFile(_element: File) {
    this.fileExplorerService.InUse(_element.path)
      .subscribe((_result: IReturnState) => {
        // Determine message in case of file-usage.
        var msg = ((_result.data as unknown as boolean) === true
          ? application.getRawText('filexplorer.delete.file.inuse.msg')
          : application.getRawText('filexplorer.delete.file.msg')).replace('{0}', _element.path);

        // File is not in use.
        this.dialogGeneric = this.dialog.open(DialogComponent, {
          autoFocus: true,
          height: '250px',
          width: '550px'
        });
        this.dialogGeneric.componentInstance.options.actionYes = true;
        this.dialogGeneric.componentInstance.options.actionNo = true;
        this.dialogGeneric.componentInstance.options.title = application.getRawText('common.warning.msg');
        this.dialogGeneric.componentInstance.options.message = msg
        this.dialogGeneric.componentInstance.onAction.subscribe((_action) => {
          if (_action.action !== 'yes') {
            return;
          }

          this.deleteFilePerform(_element);
        });

      });
  };

  private deleteFilePerform(_element: File) {
    this.fileExplorerService.DeleteFile(_element.path)
      .subscribe((_result: IReturnState) => {
        if (_result.success) {
          this.loadData();
          this.snackBarRef = this.snackBar.open(application.getRawText('common.deleted.msg'), application.getRawText('common.close.msg'));
        }
        else if (_result.stateID === 98) {
          this.snackBarRef = this.snackBar.open(application.getRawText('filexplorer.delete.file.error.notexist.msg'), application.getRawText('common.close.msg'));
        }
        else // 99
        {
          this.snackBarRef = this.snackBar.open(application.getRawText('filexplorer.delete.file.error.msg'), application.getRawText('common.close.msg'));
        }
      });
  };

  private editFile(_element: File) {
    if (_element.extension.toLowerCase() == ".html") {

    }
  }

  public prepareCopyFile(_element: File) {
    this.fileToCopy = _element;

    this.enableDirectorySelection();
    this.hideFiles();
    this.showNewFileNameInput();
    this.newFileName = _element.name;
    this.showUpload = false;

    this.getCopyFile();
  };

  private getCopyFile() {
    this.fileExplorerService.GetCopyFileName(this.fileToCopy.path, this.path + '\\' + this.fileToCopy.name).subscribe((_data: IReturnState) => {
      this.newFileName = _data.data as unknown as string;
    });
  };

  private doCopyFile() {
    this.fileExplorerService.CopyFile(this.fileToCopy.path, this.path + '\\' + this.newFileName).subscribe((_data: IReturnState) => {
      this.onClose.emit();
    });
  };

  private copyFile(_element: File) {
    this.dialogCopyFileExplorer = this.dialog.open(FileExplorerComponent, {
      autoFocus: true,
      height: '50%',
      width: '50%'
    });
    this.dialogCopyFileExplorer.componentInstance.prepareCopyFile(_element);
    this.dialogCopyFileExplorer.componentInstance.initExplorer();
    this.dialogCopyFileExplorer.componentInstance.onClose.subscribe(() => {
      this.loadData();

      this.snackBarRef = this.snackBar.open(
        application.getRawText('filexplorer.copyfile.success.msg'),
        application.getRawText('common.close.msg'));
      this.dialogCopyFileExplorer.close();
    });
  };

  public onWidgetSelected(widget: IWidgetDefinition) {
    this.selectedFile = new File();
    this.selectedFile.name = widget.name;
    this.selectedFile.path = widget.path + '/' + widget.startUrl;
    this.onSelected.emit();
  }

}
